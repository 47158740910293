<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">动物合笼</h3>
    <div class="detail">
      <el-form ref="addForm" size="small" :model="cage" class="addForm" label-suffix=":"
               :disabled="status>31"
               label-width="80px">
        <h3 class="tit">动物合笼(申请笼舍数量：{{ activeInfo.cageNum }})</h3>
        <el-tabs v-model="cage.nowCageId" class="tabsCage" type="card" @tab-click="clickTab">
          <el-tab-pane
              :key="item.id"
              v-for="item in cage.cageList"
              :label="item.number?item.number:'-'"
              :name="String(item.id)"
          >
            <div class="desc">
              <span>笼架编号：{{ item.frameNo }}</span>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div class="flex head" v-if="activeInfo.isNotApproval==1">
          <el-form-item label="笼舍编号" prop="number">
            <el-input v-model="cage.number" clearable placeholder="请输入笼舍编号" class="smallWidth"></el-input>
          </el-form-item>
          <el-form-item label="笼架编号" prop="frame_no">
            <el-input v-model="cage.frame_no" clearable placeholder="请输入笼架编号" class="smallWidth"></el-input>
          </el-form-item>
          <el-button size="small" style="margin-bottom: 18px;" @click="addTab"
                     class="addTab add-box" icon="el-icon-plus"></el-button>
        </div>
        <el-table
            :row-class-name="tableRowClassName"
            :data="cage.partAniList" border
            class="tableMember" max-height="500"
            :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
            style="width: calc(100% - 12px);font-size:14px;color:#333">
          <el-table-column label="耳号" width="150">
            <template slot-scope="scope">
              <template v-if="scope.$index==0">
                <el-select v-model="cageForm.overbit" filterable size="small" placeholder="请选择耳号"
                           @change="changeOverbit($event,cageForm)">
                  <el-option
                      v-for="(item,i) in overbitList"
                      :key="i"
                      :label="item.overbit"
                      :value="String(item.id)"
                      :disabled="item.disabled">
                  </el-option>
                </el-select>
              </template>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-select v-model="scope.row.overbit" filterable size="small" placeholder="请选择耳号"
                             @change="changeOverbit($event,scope.row,scope.$index)">
                    <el-option
                        v-for="(item,i) in overbitList"
                        :key="i"
                        :label="item.overbit"
                        :value="String(item.id)"
                        :disabled="item.disabled">
                    </el-option>
                  </el-select>
                </template>
                <span v-else>{{ scope.row.overbit }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
              label="性别" width="100">
            <template slot-scope="scope">
              <span v-if="scope.$index==0">{{
                  cageForm.gender == '0' ? '♀' : cageForm.gender == '1' ? '♂' : ' '
                }}</span>
              <template v-else>{{ scope.row.gender == '0' ? '♀' : scope.row.gender == '1' ? '♂' : ' ' }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
              label="出生日期" width="160"
              show-overflow-tooltip>
            <template slot-scope="scope">
              <template v-if="scope.$index==0&&cageForm.birthDate">
                {{ cageForm.birthDate  |  formatDay }}
              </template>
              <template v-else>
                <template v-if="scope.row.birthDate"> {{ scope.row.birthDate |  formatDay }}</template>
                <template v-else></template>
              </template>
            </template>
          </el-table-column>
          <el-table-column
              label="周龄" prop="weekDays"
              show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.$index==0">{{ cageForm.weekDays ? cageForm.weekDays : 0 }}</span>
              <div v-else>{{ scope.row.weekDays ? scope.row.weekDays : 0 }}</div>
            </template>
          </el-table-column>
          <el-table-column
              label="基因类型"
              v-if="activeInfo.aniOrder.isGene==1"
              show-overflow-tooltip>
            <el-table-column :label="item.title" v-for="(item,ind) in typeList" :key="ind">
              <template slot-scope="scope">
                <span v-if="scope.$index==0">{{ cageForm.typeList[ind].type }}</span>
                <div v-else>{{ scope.row.typeList[ind].type }}</div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="备注" min-width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <template v-if="scope.$index==0">
                <el-input size="small" placeholder="备注" v-model="cageForm.remark"></el-input>
              </template>
              <template v-else>
                <el-input v-if="scope.row.show" size="small" placeholder="备注" v-model="scope.row.remark"></el-input>
                <template v-else>{{ scope.row.remark }}</template>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <div class="editColor">
                <div v-if="scope.$index==0" class="addColor">
                  <el-button type="text" @click="addPart()">添加</el-button>
                  <el-button type="text" @click="reset">重置</el-button>
                </div>
                <div v-else class="editColor">
                  <el-button type="text" @click="edit(scope.row,scope.$index)">{{ scope.row.show ? '保存' : "修改" }}
                  </el-button>
                  <el-button type="text" @click="copy(scope.row,scope.$index)">复制</el-button>
                  <el-button type="text" @click="deleteA(scope.row,scope.$index)">删除</el-button>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="fromSave">
          <el-button type="primary" size="small" @click="cageSubmit(1)">分笼完成</el-button>
          <el-button plain type="primary" size="small" @click="cageSubmit(0)">保存</el-button>
        </div>
      </el-form>
    </div>
    <!--    编号填写-->
    <el-dialog
        :close-on-click-modal="false"
        title="编辑笼舍"
        class="flex dialogForm "
        top="0"
        :visible.sync="dialogFormVisible"
        width="400px">
      <el-input v-model="diaInfo.number" autocomplete="off" style="margin-bottom: 18px"
                placeholder="请输入笼舍编号"></el-input>
      <el-input v-model="diaInfo.frameNo" autocomplete="off" placeholder="请输入笼架编号"></el-input>
      <div slot="footer">
        <el-button size="small" type="primary" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import moment from "moment";

moment.locale("zh-cn")
const tableBegin = [{
  cageId: null,
  gender: 0,
  overbit: "",
  remark: "",
  subId: null,
  birthDate: "",
  weekDays: "",
  show: false,
  typeList: [],
}]

export default {
  name: "ensemble",
  props: ["activeInfo", "status"],
  inject: ['reload'],
  data() {
    return {
      cage: {
        cageList: [],
        nowNumber: '',
        nowCageId: '',
        totalCageNum: null,
        partAniList: [],
        tabIndex: 0,
        number: '',//笼舍编号
        frame_no: '',//笼架编号
      },
      cageForm: {
        gender: 0,
        overbit: "",
        remark: "",
        weekDays: "",
        birthDate: "",
        typeList: []
      },
      mateAniCageList: [],//存放动物列表
      overbitList: [],//耳号列表
      typeList: [],//基因类型
      options: [
        {
          value: 0,
          label: '雌性'
        }, {
          label: '雄性',
          value: 1
        }
      ],
      // 出生日期处理
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e7;
        },
      },
      dialogFormVisible: false,//弹窗
      diaInfo: {
        number: '',
        frameNo: '',
        cageId: ''
      }
    }
  },
  mounted() {
    this.getQuarantine()
    this.getData()
  },
  methods: {
    // 获取检疫合格的老鼠
    getQuarantine() {
      this.$get("/ani/quarResult/" + this.activeInfo.id).then(res => {
        if (res.status == 200) {
          res.data.forEach(item => {
            item.disabled = false
          })
          this.overbitList = res.data
        }
      })
    },
    // 获取数据
    getData() {
      this.$get('/subject/order/mate/' + this.activeInfo.id, {isLabAni: this.activeInfo.isLabAni})
          .then(res => {
            this.getTypeList(res.data)

            this.cageForm.typeList = this.typeList
            tableBegin[0].typeList = this.typeList

            this.cage.partAniList = tableBegin
            res.data.cageList.forEach((item, index) => {
              this.mateAniCageList[index] = JSON.parse(JSON.stringify(tableBegin))
            })

            this.cage.cageList = res.data.cageList

            this.cage.nowCageId = String(res.data.cageList[0].id)


            /* if (this.cage.nowNumber == null) {
               this.$message.info("请点击并且编辑第一个笼舍编号")
             }*/

            this.cage.totalCageNum = this.cage.cageList.length


          }).catch((err) => {
        this.$message.error("加载数据失败")
      })
    },
    // 获取基因类型
    getTypeList(data) {
      // 赋值typeList
      let arr = data.geneKeys.split(',')
      arr.forEach(item => {
        this.typeList.push({
          title: item,
          type: ''
        })
      })
    },
    //选择耳号
    changeOverbit(value, row) {
      this.overbitList.forEach((item, index) => {
        if (item.id == value) {
          //回填对应小鼠数据
          row.gender = item.gender
          row.birthDate = item.birthDate
          row.typeList = JSON.parse(item.gene)
          row.subId = item.subId
          row.overbit = item.overbit
          row.quarResultId = item.id
          /*//判断是否是实验室，是就是检疫id，不是就动物id  1.来源于实验室  0 不是
          if (this.activeInfo.isLabAni == 1) {
            row.quarResultId = item.id
          }*/
          this.selectBir(row.birthDate, row)
        }
      })

    },
    // 选择出生日期 改变周龄
    selectBir(value, row, index) {
      if (value) {
        let today = moment(new Date()).format("YYYY-MM-DD");
        let birDay = moment(value).format("YYYY-MM-DD");
        let diffTime = moment(today).diff(moment(birDay), 'days');
        let weeksAge = diffTime / 7
        row.weekDays = Math.round(weeksAge)
      }
    },
    //添加
    addPart() {
      if ($.trim(this.cageForm.overbit).length === 0) {
        this.$message.warning("耳号不能为空");
        return;
      }
      if ($.trim(this.cageForm.gender).length === 0) {
        this.$message.warning("性别不能为空");
        return;
      }
      if ($.trim(this.cageForm.birthDate).length === 0) {
        this.$message.warning("出生日期不能为空");
        return;
      }


      this.cageForm.cageId = this.cage.cageList[this.cage.tabIndex].id
      this.cageForm.subId = this.cage.cageList[this.cage.tabIndex].subId

      let result = this.deepClone4(this.cageForm)
      this.cage.partAniList.push(result)


      // 当只提交一个
      if (this.cage.nowCageId == this.cage.cageList[0].id) {
        this.mateAniCageList[this.cage.tabIndex] = this.cage.partAniList;
      }

      this.cage.partAniList.forEach((item, index) => {
        item.gene = JSON.stringify(item.typeList)
      })

      this.typeList.forEach(item => {
        item.type = ""
      })

      this.overbitList.forEach(obj => {
        this.cage.partAniList.forEach(item => {
          if (obj.id === item.quarResultId) {
            obj.disabled = true
          }
        })
      })


      this.cageForm = {
        gender: 0,
        overbit: "",
        remark: "",
        weekDays: "",
        birthDate: "",
      }
      this.cageForm.typeList = this.typeList
    },
    deepClone4(target, map = new Map()) {
      // 检测数据类型
      if (typeof target === 'object' && target !== null) {
        // 克隆数据之前，判断之前是否克隆过
        let cache = map.get(target);
        if (cache) {
          return cache;
        }
        // 创建一个容器
        const isArray = Array.isArray(target)
        const result = isArray ? [] : {};
        // 将新结果存入到容器中
        map.set(target, result);
        // 正则判断
        if (target.constructor === RegExp) {
          return target;
        } else if (isArray) {
          // 遍历数组
          target.forEach((item, index) => {
            result[index] = this.deepClone4(item, map);
          });
        } else {// 对象
          Object.keys(target).forEach(key => {
            result[key] = this.deepClone4(target[key], map);
          });
        }
        return result;
      } else {
        return target;
      }
    },
// 重置
    reset() {
      this.typeList.forEach(item => {
        item.type = ""
      })

      this.cageForm = {
        gender: 0,
        overbit: "",
        remark: "",
        weekDays: "",
        birthDate: "",
      }
      this.cageForm.typeList = this.typeList
    },
    //tab切换
    clickTab(ev) {
      let frameNo, nowNumber
      frameNo = this.cage.cageList[ev.index].frameNo;//笼架编号
      nowNumber = this.cage.cageList[ev.index].number;//笼舍编号

      if (this.activeInfo.isNotApproval == 1 && (frameNo === null || nowNumber === null)) {
        this.dialogFormVisible = true
        this.diaInfo = {
          cageId: this.cage.cageList[ev.index].id,
          frameNo: this.cage.cageList[ev.index].frameNo,
          number: this.cage.cageList[ev.index].number
        }
      }

      this.typeList.forEach(item => {
        item.type = ""
      })

      this.cageForm = {
        gender: 0,
        overbit: "",
        remark: "",
        weekDays: "",
        birthDate: "",
      }
      this.cageForm.typeList = this.typeList
      this.cageForm.cageId = this.cage.cageList[ev.index].id
      this.cageForm.subId = this.cage.cageList[ev.index].subId
      this.mateAniCageList[this.cage.tabIndex] = this.cage.partAniList;
      this.cage.partAniList = this.mateAniCageList[ev.index];
      this.cage.tabIndex = ev.index;//笼舍列表下标
    },
    // 提交编辑好的
    submit() {
      let params = {
        number: this.diaInfo.number,//笼舍编号
        sub_id: this.activeInfo.id,//课题id
        is_product: this.activeInfo.isProd,//0：实验方案，1：生产方案
        frameNo: this.diaInfo.frameNo,
        id: this.diaInfo.cageId,//笼舍id
      }
      this.$put('/subject/cage', params).then(res => {
        if (res.status == 200) {
          this.dialogFormVisible = false
          this.reload();
        }
      })
    },

    // 设置第一行样式
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 0) {
        return 'formAdd';
      }
      return '';
    },
    // 编辑
    edit(row, index) {
      row.show = row.show ? false : true
      this.cage.partAniList.forEach((item, index) => {
        item.gene = JSON.stringify(item.typeList)
      })
      if(row.show){
        this.overbitList.forEach(obj => {
          this.cage.partAniList.forEach(item => {
            if (obj.id === item.quarResultId) {
              obj.disabled = true
            }
          })
        })
      }else {
        this.overbitList.forEach(obj => {
          if (obj.id === row.quarResultId) {
            obj.disabled = true
          }
        })
      }
      this.$set(this.cage.partAniList, index, row)
    },
    // 复制
    copy(val, index) {
      this.cage.partAniList.splice(index, 0, JSON.parse(JSON.stringify(val)))
    },
    // 删除
    deleteA(row,index) {
      this.overbitList.forEach(obj => {
        if (obj.id === row.quarResultId) {
          obj.disabled = false
        }
      })
      this.cage.partAniList.splice(index, 1)
    },
    // 添加笼舍
    addTab() {
      if (this.cage.number == '') {
        this.$message.warning("请先输入笼舍编号！")
        return;
      }
      if (this.cage.frame_no == '') {
        this.$message.warning("请先输入笼架编号！")
        return;
      }
      let params = {
        number: this.cage.number,
        subId: this.activeInfo.id,//课题id
        isProduct: this.activeInfo.isProd,//0：实验方案，1：生产方案
        frameNo: this.cage.frame_no
      }
      //接口新增
      this.$post("/subject/cage", params).then(res => {
        this.$message.success("添加成功")
        this.reload();
        //初始化
        this.cage.number = ''
        this.cage.frame_no = ''
      }).catch(() => {
        this.$message.error("添加失败")
      })

    },
    cageSubmit(state) {
      let lists = [];
      this.mateAniCageList.forEach((item, index) => {
        item.splice(0, 1)
        //数据处理
        item.forEach((item2, index2) => {
          let obj = {
            cageId: item2.cageId,
            subId: item2.subId,
            remark: item2.remark,
            quarResultId: item2.quarResultId
          }
          lists.push(obj)
        })
      })

      if (lists.length == 0) {
        this.$message.warning('请选择动物信息！')
        return false;
      }
      let params = {
        mateAniCageList: lists,
        state: state,
        subId: this.activeInfo.id,
        isLabAni: this.activeInfo.isLabAni//是否来自实验室
      }
      this.$postJson("/subject/mate", params).then(res => {
        this.$message.success("提交成功！")
        this.$router.go(-1)
      }).catch(() => {
        this.$message.error("操作失败")
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";
</style>
